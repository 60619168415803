import React from "react";

const Step = () => {
  return (
    <div
      data-aos="fade-up"
      id="how"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="f51618fb-0edb-4bcb-b35a-ffc770941286"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#f51618fb-0edb-4bcb-b35a-ffc770941286)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Konversi</span>
          </span>{" "}
          saldo PayPal ke Rupiah cukup dengan 3 langkah.
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Cukup dengan tiga langkah mudah kamu sudah bisa menukar saldo PayPal
          menjadi Rupiah.
        </p>
      </div>
      <div className="grid gap-8 row-gap-0 lg:grid-cols-3">
        <div className="relative text-center">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-primary-50 sm:w-20 sm:h-20">
            <svg
              className="w-12 h-12 text-primary-700 sm:w-16 sm:h-16"
              fill="currentColor"
              viewBox="0 0 48 48"
            >
              <path
                fillRule="evenodd"
                d="M39 13a3 3 0 0 0-3 3v2h6v-2a3 3 0 0 0-3-3Zm3 7h-6v16.5l3 4.5l3-4.5V20ZM6 9v30a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3Zm14 6a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Zm-1 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Zm-9-3v3h3v-3h-3Zm-1-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1Zm6.707-10.293a1 1 0 0 0-1.414-1.414L13 17.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L13 20.414l4.707-4.707Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <h6 className="mb-2 text-2xl font-extrabold">Langkah 1</h6>
          <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
            Atur jumlah, pilih jenis penukaran yang kamu inginkan dan masukkan
            informasi dasar yang kami butuhkan.
          </p>

          <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
            <svg
              className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
        </div>
        <div className="relative text-center">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-primary-50 sm:w-20 sm:h-20">
            <svg
              className="w-12 h-12 text-primary-700 sm:w-16 sm:h-16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M11.398 21.009H8.874a.392.392 0 0 1-.33-.15a.459.459 0 0 1-.09-.363c.04-.241.09-.559.152-.963l.114-.727a313.336 313.336 0 0 1 .348-2.188v-.02l.188-1.164l.1-.619v-.005c.072-.445.129-.798.17-1.061a.39.39 0 0 1 .433-.371h1.52a10.08 10.08 0 0 0 2.171-.212a6.09 6.09 0 0 0 2.886-1.449a6.084 6.084 0 0 0 1.56-2.473c.152-.436.27-.884.352-1.338c.007-.042.015-.066.025-.074a.03.03 0 0 1 .025-.012h.01a.31.31 0 0 1 .062.035c.525.397.876.982.98 1.632c.118.68.105 1.376-.04 2.051a5.406 5.406 0 0 1-1.857 3.35a6.053 6.053 0 0 1-3.825 1.116h-.439a.662.662 0 0 0-.444.166a.722.722 0 0 0-.239.427l-.04.194l-.554 3.478l-.02.151a.706.706 0 0 1-.249.427a.67.67 0 0 1-.445.162Zm-3.578-2H4.855a.473.473 0 0 1-.369-.165a.466.466 0 0 1-.115-.39l2.331-14.79a.775.775 0 0 1 .277-.483a.783.783 0 0 1 .518-.19h6.014c.33.013.658.057.98.131c.382.073.758.18 1.122.32a3.425 3.425 0 0 1 1.645 1.238a3.41 3.41 0 0 1 .568 1.972a7.11 7.11 0 0 1-.46 2.374a4.909 4.909 0 0 1-3.042 3.165a8.046 8.046 0 0 1-2.535.425c-.01.006-.44.007-.9.007l-.9-.007a1.083 1.083 0 0 0-1.187.964c-.013.054-.317 1.947-.855 5.329a.107.107 0 0 1-.128.105l.001-.005Z" />
            </svg>
          </div>
          <h6 className="mb-2 text-2xl font-extrabold">Langkah 2</h6>
          <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
            Lakukan pembayaran langsung melalui website kami menggunakan akun
            PayPal kamu.
          </p>

          <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
            <svg
              className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
        </div>
        <div className="relative text-center">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-primary-50 sm:w-20 sm:h-20">
            <svg
              className="w-12 h-12 text-primary-700 sm:w-16 sm:h-16"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <h6 className="mb-2 text-2xl font-extrabold">Langkah 3</h6>
          <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
            Kami segera memproses permintaan kamu dan mengirimkan saldo dalam
            bentuk Rupiah.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Step;
