import React from "react";

const Item: React.FC<{ title: string }> = ({ title, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium text-left">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full min-w-[2rem]">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

const Faq = () => {
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "Apa itu Rupiah.trade?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>Rupiah.trade adalah penyedia layanan konversi saldo PayPal menjadi saldo GoPay, Dana, Ovo, LinkAja, ShopeePay, rekening bank, pulsa, item game, dan lainnya.</p>"
          }
        }, {
          "@type": "Question",
          "name": "Berapa minimal saldo PayPal yang dapat saya konversi?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Minimal saldo yang dapat kamu konversi adalah 1 USD."
          }
        }, {
          "@type": "Question",
          "name": "Saldo PayPal saya bisa dikonversi menjadi apa saja?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Kamu dapat mengonversi saldo PayPal kamu menjadi rupiah yang dapat kamu cairkan ke e-Wallet favoritmu, ke semua bank lokal atau mengubahnya menjadi pulsa bahkan item game."
          }
        }, {
          "@type": "Question",
          "name": "Berapa lama waktu yang dibutuhkan agar uang masuk ke rekening saya?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Setelah pembayaran kamu telah kami terima, kami akan segera memproses pesanan kamu dalam hitungan menit untuk trasanksi otomatis dan paling lambat 1 x 24 jam untuk transaksi manual."
          }
        }]
      }`,
        }}
      ></script>
      <div data-aos="fade-up" id="faq" className="bg-primary-50">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col mb-16 sm:text-center">
              <div className="mb-6 sm:mx-auto">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary-50">
                  <svg
                    className="w-8 h-8 text-primary-700"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    />
                  </svg>
                </div>
              </div>
              <div className="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                  <span className="relative inline-block">
                    <svg
                      viewBox="0 0 52 24"
                      fill="currentColor"
                      className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                    >
                      <defs>
                        <pattern
                          id="ec5d8ef5-b853-4714-b94f-df28ec98eeb7"
                          x="0"
                          y="0"
                          width=".135"
                          height=".30"
                        >
                          <circle cx="1" cy="1" r=".7" />
                        </pattern>
                      </defs>
                      <rect
                        fill="url(#ec5d8ef5-b853-4714-b94f-df28ec98eeb7)"
                        width="52"
                        height="24"
                      />
                    </svg>
                    <span className="relative">Pertanyaan</span>
                  </span>{" "}
                  dan jawaban
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                  Berikut adalah pertanyaan umum yang sering ditanyakan beserta
                  jawabanya.
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <Item title="Apa itu Rupiah.trade?">
                Rupiah.trade adalah penyedia layanan konversi saldo PayPal
                menjadi saldo GoPay, Dana, Ovo, LinkAja, ShopeePay, rekening
                bank, pulsa, item game, dan lainnya.
              </Item>
              <Item title="Berapa minimal saldo PayPal yang dapat saya konversi?">
                Minimal saldo yang dapat kamu konversi adalah 1 USD.
              </Item>
              <Item title="Saldo PayPal saya bisa dikonversi menjadi apa saja?">
                Kamu dapat mengonversi saldo PayPal kamu menjadi rupiah yang
                dapat kamu cairkan ke e-Wallet favoritmu, ke semua bank lokal
                atau mengubahnya menjadi pulsa bahkan item game.
              </Item>
              <Item title="Berapa lama waktu yang dibutuhkan agar uang masuk ke rekening saya?">
                Setelah pembayaran kamu telah kami terima, kami akan segera
                memproses pesanan kamu dalam hitungan menit untuk trasanksi
                otomatis dan paling lambat 1 x 24 jam untuk transaksi manual.
              </Item>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
