import React from "react";

const Stats = () => {
  return (
    <div data-aos="fade-up" id="stats" className="bg-primary-50">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="f51618fb-0edb-4bcb-b35a-ffc770941286"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#f51618fb-0edb-4bcb-b35a-ffc770941286)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">
                <span className="text-primary-700 font-extrabold">900+</span>{" "}
                Transaksi
              </span>
            </span>{" "}
            telah kami selesaikan
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Kami telah berhasil menyelesaikan lebih dari 900 transaksi dari
            ratusan pengguna kami.
          </p>
        </div>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="text-center">
            <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-cyan-500 sm:w-12 sm:h-12">
              <svg
                className="w-8 h-8 text-white fill-white sm:w-10 sm:h-10"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M17.84,6.31c-0.2,0-0.38,0-0.55,0c-3.33,0-6.65,0-9.98-0.01c-0.13,0-0.27-0.01-0.4,0c-0.39,0.03-0.7,0.33-0.75,0.7
	C6.1,7.4,6.3,7.73,6.72,7.85c0.22,0.06,0.47,0.07,0.7,0.07c3.27,0,6.53-0.01,9.8,0.01c0.57,0,1.16,0.07,1.72,0.2
	c1.64,0.38,2.63,1.46,2.86,3.09c0.32,2.17,0.29,4.35-0.35,6.48c-0.58,1.92-1.95,2.83-3.89,3.05c-3,0.34-6.01,0.29-9.02,0.1
	c-0.9-0.06-1.82-0.11-2.68-0.33c-2.09-0.53-3.35-2.12-3.61-4.3C1.97,13.91,1.9,11.6,2.17,9.3C2.29,8.35,2.51,7.4,2.74,6.47
	c0.39-1.6,1.47-2.51,3.03-2.92c2.03-0.54,4.1-0.64,6.18-0.48c1.15,0.09,2.3,0.25,3.42,0.5c1.15,0.26,2.05,0.91,2.42,2.12
	c0.03,0.1,0.05,0.2,0.06,0.3C17.85,6.08,17.84,6.17,17.84,6.31z M17.99,12.36c-0.52,0.04-0.9,0.28-1.12,0.74
	c-0.21,0.45-0.21,0.96,0.16,1.28c0.4,0.34,0.36,0.73,0.36,1.15c0,0.19-0.01,0.39,0,0.58c0.02,0.36,0.28,0.63,0.61,0.63
	c0.33,0.01,0.62-0.25,0.64-0.61c0.02-0.3,0.02-0.59,0-0.89c-0.02-0.31,0.04-0.55,0.29-0.77c0.38-0.33,0.44-0.91,0.22-1.35
	C18.92,12.64,18.53,12.39,17.99,12.36z"
                />
              </svg>
            </div>
            <h6 className="text-4xl font-bold text-primary-700">187</h6>
            <p className="mb-2 font-bold text-md">Konversi ke GoPay</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-light-blue-500 sm:w-12 sm:h-12">
              <svg
                className="w-8 h-8 text-white fill-white sm:w-10 sm:h-10"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12.01,1.97C17.47,1.94,22.03,6.43,22,12.05c-0.03,5.44-4.38,9.99-10.01,9.98C6.26,22.02,1.96,17.35,2,11.92
	C2.04,6.4,6.54,1.94,12.01,1.97z M17.84,12.02c0-0.91,0-1.83,0-2.74c0-0.22-0.05-0.41-0.23-0.53c-0.58-0.43-1.23-0.67-1.95-0.72
	c-0.78-0.06-1.53,0.12-2.26,0.38c-1.06,0.37-2.1,0.78-3.16,1.12C8.98,9.93,7.73,9.91,6.56,9.16C6.28,8.97,6.18,9.03,6.18,9.37
	c0,1.81,0,3.61,0,5.42c0,0.22,0.07,0.41,0.27,0.54c0.62,0.39,1.3,0.6,2.03,0.63c0.9,0.04,1.76-0.19,2.61-0.47
	c1.13-0.37,2.23-0.85,3.42-1.04c1.06-0.17,2.07-0.05,3.01,0.51c0.28,0.16,0.33,0.13,0.33-0.19C17.85,13.85,17.84,12.94,17.84,12.02z
	"
                />
              </svg>
            </div>
            <h6 className="text-4xl font-bold text-primary-700">236</h6>
            <p className="mb-2 font-bold text-md">Konversi ke Dana</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-primary-900 sm:w-12 sm:h-12">
              <svg
                className="w-8 h-8 text-white fill-white sm:w-10 sm:h-10"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="12" r="3.99" />
                <path
                  d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,18.35c-3.51,0-6.35-2.84-6.35-6.35
		S8.49,5.65,12,5.65c3.51,0,6.35,2.84,6.35,6.35S15.51,18.35,12,18.35z"
                />
              </svg>
            </div>
            <h6 className="text-4xl font-bold text-primary-700">219</h6>
            <p className="mb-2 font-bold text-md">Konversi ke OVO</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-teal-600 sm:w-12 sm:h-12">
              <svg
                className="w-8 h-8 text-white fill-white sm:w-10 sm:h-10"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M8.8,10.05H7.87v1.4H8.8c0.25,0,0.44-0.06,0.57-0.18c0.13-0.12,0.19-0.29,0.19-0.52c0-0.22-0.07-0.38-0.19-0.51
		C9.23,10.11,9.04,10.05,8.8,10.05z"
                />
                <path
                  d="M15.13,11.42c-0.27,0-0.5,0.1-0.69,0.29c-0.19,0.2-0.28,0.47-0.28,0.81c0,0.35,0.09,0.62,0.28,0.82
		c0.19,0.2,0.42,0.29,0.69,0.29c0.27,0,0.5-0.1,0.69-0.3c0.19-0.2,0.28-0.47,0.28-0.81c0-0.35-0.09-0.62-0.28-0.81
		C15.63,11.51,15.4,11.42,15.13,11.42z"
                />
                <path
                  d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M9.4,15.19l-1.33-2.47h-0.2v2.47H6.01
		v-6.7h2.94c0.54,0,0.99,0.09,1.37,0.28s0.66,0.44,0.85,0.77c0.19,0.33,0.29,0.69,0.29,1.1c0,0.46-0.13,0.86-0.38,1.2
		c-0.25,0.35-0.62,0.59-1.1,0.74l1.51,2.61H9.4z M17.69,13.97c-0.2,0.42-0.47,0.73-0.82,0.96c-0.35,0.22-0.73,0.33-1.16,0.33
		c-0.36,0-0.67-0.07-0.94-0.22c-0.26-0.15-0.47-0.34-0.62-0.59v3.29h-1.86V9.86h1.86v0.74c0.15-0.25,0.35-0.44,0.62-0.59
		c0.27-0.15,0.58-0.22,0.94-0.22c0.42,0,0.81,0.11,1.16,0.33c0.35,0.22,0.62,0.54,0.82,0.95c0.2,0.41,0.3,0.89,0.3,1.44
		C17.99,13.07,17.89,13.55,17.69,13.97z"
                />
              </svg>
            </div>
            <h6 className="text-4xl font-bold text-primary-700">296</h6>
            <p className="mb-2 font-bold text-md">Konversi ke Lainnya</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
