import { StaticImage } from "gatsby-plugin-image";
import React from "react";
// @ts-ignore
import ReactRotatingText from "react-rotating-text";




export const Header = () => {
  return (
    <div
      data-aos="fade-up"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Ubah saldo PayPal <br className="hidden md:block" />
              jadi saldo{""}
              <span className="relative text-primary-700 inline-block px-2 font-extrabold">
                <ReactRotatingText
                  items={["GoPay", "Dana", "OVO", "LinkAja", "ShopeePay"]}
                  cursor={false}
                />
                <span className="border-r-2 border-slate-800 animate-ping" />
              </span>
              <br />
              dalam sekejap.
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              Rupiah.trade merupakan jasa konversi saldo PayPal USD ke saldo
              GoPay, Dana, LinkAja, Rekening Bank dan Pulsa terpercaya di
              Indonesia.
            </p>
          </div>
          <div className="flex items-center">
            <a
              href="https://app.rupiah.trade"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-xl bg-primary-700 hover:bg-primary-500 hover:shadow-2xl focus:shadow-outline focus:outline-none"
            >
              Tukar Sekarang
            </a>
            <a
              href="#feature"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-primary-700 hover:text-primary-800"
            >
              Selengkapnya
            </a>
          </div>
        </div>
        <div className="relative">
        <StaticImage formats={[`auto`, `webp`, `avif`]} width={600} height={500} src="../../static/images/head.png" placeholder="blurred" alt="Rupiah.trade" />
        </div>
      </div>
    </div>
  );
};

export default Header;
