import React from "react";

export default function Features() {
  return (
    <div data-aos="fade-up" id="feature" className="relative bg-primary-50">
      <svg
        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-primary-50"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="currentColor"
          d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
        />
      </svg>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="ea469ae8-e6ec-4aca-8875-fc402da4d16e"
                    x={0}
                    y={0}
                    width=".135"
                    height=".30"
                  >
                    <circle cx={1} cy={1} r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#ea469ae8-e6ec-4aca-8875-fc402da4d16e)"
                  width={52}
                  height={24}
                />
              </svg>
              <span className="relative">Komitmen kami adalah</span>
            </span>{" "}
            memberikan yang terbaik untuk anda.
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Nikmati kemudahan konversi saldo PayPal dengan beragam fitur yang
            kami tawarkan
          </p>
        </div>
        <div className="grid gap-8 row-gap-10 lg:grid-cols-2">
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primary-700 shadow-lg sm:mx-auto sm:w-24 sm:h-24">
              <svg
                className="w-8 h-8 text-white sm:w-12 sm:h-12"
                stroke="currentColor"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5">
              Minimal penukaran 1 USD
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Dengan 1 USD kamu bisa menukar saldo PayPal menjadi saldo GoPay,
              Dana, Ovo, LinkAja, ShopeePay, rekening bank, pulsa dan lainnya.
            </p>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primary-700 shadow-lg sm:mx-auto sm:w-24 sm:h-24">
              <svg
                className="w-8 h-8 text-white sm:w-12 sm:h-12"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5">
              Proses mudah &amp; cepat
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Proses penukaran mudah dan cepat. Setelah saldo PayPal kamu telah
              kami terima, kami akan segera memproses transaksimu.
            </p>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primary-700 shadow-lg sm:mx-auto sm:w-24 sm:h-24">
              <svg
                className="w-8 h-8 text-white sm:w-12 sm:h-12"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                />
              </svg>
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5">
              Nilai tukar tinggi
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Kami menawarkan nilai tukar yang mengikuti kurs USD ke Rupiah saat
              ini dengan biaya murah dan transparan.
            </p>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primary-700 shadow-lg sm:mx-auto sm:w-24 sm:h-24">
              <svg
                className="w-8 h-8 text-white sm:w-12 sm:h-12"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                />
              </svg>
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5">
              Sistem pembayaran instant
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Kami menerapkan sistem pembayaran instant yang dapat kamu lakukan
              langsung melalui website kami.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
