import React from "react";
import Faq from "../components/home/faq";
import Features from "../components/home/feature";
import Footer from "../components/footer";
import Header from "../components/header";
import Nav from "../components/nav";
import Rate from "../components/home/rate";
import SEO from "../components/seo";
import Stats from "../components/home/stats";
import Step from "../components/home/step";

export default function Home() {
  return (
    <>
    <SEO title="Rupiah.trade: Top up saldo Gopay, Ovo, Dana, LinkAja, ShopeePay via PayPal"></SEO>
      <Nav />
      <Header />
      <Features />
      <Step />
      <Stats />
      <Rate />
      <Faq />
      <Footer />
    </>
  );
}
