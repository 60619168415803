import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { id } from "date-fns/locale";

export default function Rate() {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    axios
      .get(`https://api.rupiah.trade/exchangerates`)
      .then(function (res) {
        if (res.data.rate < 14800) {
          setRate(res.data.rate);
        } else {
          setRate(14800);
        }
      });
  });

  return (
    <>
      <div
        data-aos="fade-up"
        id="rate"
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      >
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Exchange Rate
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="7e5e8ff8-1960-4094-a63a-2a0c0f922d69"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#7e5e8ff8-1960-4094-a63a-2a0c0f922d69)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">Rate</span>
            </span>{" "}
            hari ini.
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Rate {format(new Date(), "EEEE, dd MMMM yyyy", { locale: id })}.
          </p>
        </div>
        <div className="grid max-w-md gap-10 row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
          <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white rounded-2xl shadow-lg sm:items-center hover:shadow-2xl">
            <div className="text-center">
              <div className="text-lg font-semibold">1 - 9 USD</div>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-3xl font-bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    maximumSignificantDigits: 5,
                  }).format((rate - rate * 0.04) * 0.88)}
                </div>
                <div className="text-gray-700">/USD</div>
              </div>
            </div>
            <div>
              <a
                href="https://app.rupiah.trade"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-xl bg-gray-900 hover:bg-gray-800 hover:shadow-2xl focus:shadow-outline focus:outline-none my-6"
              >
                Tukar Sekarang
              </a>
              <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                Kurs USD ke IDR saat ini adalah{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  maximumSignificantDigits: 7,
                }).format(rate - rate * 0.04)}
                . Tetapi kami memotong 12% pada transaksi antara 0 - 9 USD
                sebagai biaya layanan kami.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col justify-between p-8 transition-shadow duration-300 bg-primary-50 rounded-2xl shadow-lg sm:items-center hover:shadow-2xl ">
            <div className="absolute inset-x-0 top-0 flex justify-center -mt-3">
              <div className="inline-block px-3 py-1 text-xs font-medium tracking-wider text-white uppercase rounded bg-primary-700">
                Banyak Ditukar
              </div>
            </div>
            <div className="text-center">
              <div className="text-lg font-semibold">10 - 49 USD</div>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-3xl font-bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    maximumSignificantDigits: 5,
                  }).format((rate - rate * 0.04) * 0.9)}
                </div>
                <div className="text-gray-700">/USD</div>
              </div>
            </div>
            <div>
              <a
                href="https://app.rupiah.trade"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-xl bg-primary-700 hover:bg-primary-500 hover:shadow-2xl focus:shadow-outline focus:outline-none my-6"
              >
                Tukar Sekarang
              </a>
              <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                Kurs USD ke IDR saat ini adalah{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  maximumSignificantDigits: 7,
                }).format((rate - rate * 0.04))}
                . Tetapi kami memotong 10% pada transaksi antara 10 - 49 USD
                sebagai biaya layanan kami.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white rounded-2xl shadow-lg sm:items-center hover:shadow-2xl">
            <div className="text-center">
              <div className="text-lg font-semibold">50 - ∞ USD</div>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-3xl font-bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    maximumSignificantDigits: 5,
                  }).format((rate - rate * 0.04) * 0.95)}
                </div>
                <div className="text-gray-700">/USD</div>
              </div>
            </div>
            <div>
            <a
                href="https://app.rupiah.trade"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-xl bg-gray-900 hover:bg-gray-800 hover:shadow-2xl focus:shadow-outline focus:outline-none my-6"
              >
                Tukar Sekarang
              </a>
              <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                Kurs USD ke IDR saat ini adalah{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  maximumSignificantDigits: 7,
                }).format((rate - rate * 0.04))}
                . Tetapi kami memotong 5% pada transaksi diatas 50 USD
                sebagai biaya layanan kami.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
